.option:disabled {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 20px;

  font-size: 14px;
  line-height: 17px;
  color: #4A61DD;

  background-color: #F0F3FF;
  border: none;
  border-radius: 6px;
}

.option_checked:disabled {
  background-color: #ffffff;

  border: 1px solid #DEE2F2;
}